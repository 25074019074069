<!--
 * @Description: 公共电站运营报表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button
              v-rbac="'report:stationOperation:export'"
              type="plain"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>
<script>
  import { reportforms, treeUtils, investmentData } from '@/service/apis';
  import { offlineExport, code } from '@/utils';
  // import { cloneDeep } from '../../../../utils';

  export default {
    name: 'publicPile',
    components: {
      // AddOrEdit,
    },
    data() {
      return {
        searchParams: {
          stationCode: '',
          stationName: '',
          belongStartTime: '',
          belongEndTime: '',
          investorId: '',
          provinceId: '',
          cityId: '',
          times: [],
        },
        addressData: [],
        investData: [],
        // 列表表格
        tableTitle: '公共充电站运营报表',
        tableData: [],
        tableColumns: [
          { label: '站点编码', prop: 'stationCode', minWidth: '200px' },
          {
            label: '站点名称',
            prop: 'stationName',
            minWidth: '150px',
          },
          {
            label: '省',
            prop: 'provinceName',
            minWidth: '150px',
          },
          { label: '市', prop: 'cityName', minWidth: '150px' },

          { label: '投资方', prop: 'investorName', minWidth: '150px' },
          {
            label: '入驻时间',
            prop: 'stationEnterTime',
            minWidth: '200px',
          },
          {
            label: '开始时间',
            prop: 'belongStartTime',
            minWidth: '150px',
          },
          {
            label: '结束时间',
            prop: 'belongEndTime',
            minWidth: '150px',
          },

          {
            label: '总消费次数',
            prop: 'consumeCount',
            minWidth: '150px',
          },
          {
            label: '总消费金额(元)',
            prop: 'consumeAmount',
            minWidth: '150px',
          },
          {
            label: '服务费金额(元)',
            prop: 'serviceAmount',
            minWidth: '150px',
          },
          {
            label: '电费金额(元)',
            prop: 'chargeAmount',
            minWidth: '150px',
          },
          {
            label: '总充电时长(小时)',
            prop: 'chargedTime',
            minWidth: '150px',
          },
          {
            label: '总用户数',
            prop: 'userCount',
            minWidth: '150px',
          },
          {
            label: '总充电电量(度)',
            prop: 'chargedPower',
            minWidth: '150px',
          },
          {
            label: '设备利用率',
            prop: 'deviceUseRatio',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return row.deviceUseRatio || row.deviceUseRatio === 0
                  ? `${row.deviceUseRatio}%`
                  : '——';
              },
            },
          },
          {
            label: '消费枪数',
            prop: 'consumePileCount',
            minWidth: '150px',
          },
          {
            label: '平均枪数',
            prop: 'avgPileCount',
            minWidth: '150px',
          },

          {
            label: '平均单枪消费次数',
            prop: 'avgOnePileConsumeCount',
            minWidth: '150px',
          },
          {
            label: '平均单枪消费金额',
            prop: 'avgOnePileConsumeAmount',
            minWidth: '150px',
          },
          {
            label: '平均单枪充电电量',
            prop: 'avgOnePileChargedPower',
            minWidth: '150px',
          },
          {
            label: '平均单枪用户数',
            prop: 'avgOnePileUserCount',
            minWidth: '150px',
          },
        ],
        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'stationCode',
            label: '站点编码',
            placeholder: '请输入站点编码',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '站点名称',
            placeholder: '请输入站点名称',
          },

          {
            comName: 'YkcDatePicker',
            key: 'times',
            label: '统计时间',
            placeholder: '请选择统计时间',
          },
          {
            comName: 'YkcCascader',
            key: 'ids',
            label: '省市',
            placeholder: '请选择省市',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
            filterable: true,
            checkStrictly: true,
          },

          {
            comName: 'YkcDropdown',
            key: 'investorId',
            label: '投资方',
            placeholder: '请选择投资方',
            remote: true,
            data: this.investData,
          },
        ];
      },
    },
    mounted() {
      this.requestGetDistrict();
      this.requestInvestList();
      // this.requestList();
    },
    methods: {
      /**
       * 列表查询
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...this.pageInfo,
        };
        reportforms
          .getStationOperationList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取投资方列表
       */
      requestInvestList() {
        investmentData
          .investmentList({
            current: 1,
            size: 99999,
          })
          .then(res => {
            const params = res.records;
            params.map(item => {
              return this.investData.push({
                id: item.id,
                name: item.investorName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },

      /**
       * 导出
       */
      doExportFile() {
        if (this.searchParams.times && this.searchParams.times.length) {
          [this.searchParams.belongStartTime, this.searchParams.belongEndTime] =
            this.searchParams.times;
        } else {
          this.$message.warning('请选择统计时间后进行导出');
          return;
        }

        offlineExport(
          {
            downloadType: 'statistics_operate_order_export',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },

      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        if (searchData.times && searchData.times.length) {
          [this.searchParams.belongStartTime, this.searchParams.belongEndTime] = searchData.times;
        } else {
          this.$message.warning('请选择统计时间后进行查询');
          return;
        }

        if (searchData.ids && searchData.ids.length) {
          [this.searchParams.provinceId] = searchData.ids;
          if (searchData.ids.length > 1) {
            [this.searchParams.provinceId, this.searchParams.cityId] = searchData.ids;
          }
        }
        // Object.assign(this.searchParams, searchData);
        // const params = cloneDeep(this.searchParams);
        // delete params.times;
        // delete params.ids;
        // const flag = this.isAllValuesEmpty(params);
        // if (flag) {
        this.requestList();
        // } else {
        //   this.$message.warning('请至少输入一个查询条件');
        //   return;
        // }
      },
      /**
       * 判断obj所有key是否为空
       * */
      isAllValuesEmpty(obj) {
        let flag = false;
        Object.keys(obj).forEach(value => {
          const item = obj[value];
          if (item !== '' && item !== null && item !== undefined) {
            flag = true;
            return true;
          }
          return false;
        });
        return flag;
      },

      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
